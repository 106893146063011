import React from "react"
import { Box } from "ticeboxes"
import { H1, H2 } from "../components/general/Typography"
import Layout from "../components/layout/Layout"
import { Container, Row, Col } from "reactstrap"
import Head from "../components/layout/Head"
import WinkelCardList from "../components/winkel/WinkelCardList"

interface Props {}

const service: React.FC<Props> = () => {
  return (
    <>
      <Head
        title="Winkel"
        description="Vind de verschillende artikelen aangeboden door Eyefashion by Rami. Lees meer over contactlenzen, verschillende monturen, zonnebrillen op sterkte en nachtlenzen."
      ></Head>
      <Layout>
        <Container>
          <Row>
            <Col xs="12">
              <Box text-center my="5">
                <H1>Winkel</H1>
                <H2>Eyefashion by Rami</H2>
              </Box>
              <Box my="5">
                <WinkelCardList />
              </Box>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default service
